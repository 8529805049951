import React, { Component } from "react";
import { Card, Form, Table, Alert, Grid, Button } from "tabler-react";
import { connect } from "react-redux";
import { actionGetClient } from "redux/actions/ClientsActions";
import {
  actionCleanReports,
  actionGetBirthdateReport
} from "redux/actions/ReportsActions";
import { Pagination } from "components/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { DATEPICKER } from "constants/ConstantsComponents";
import { CONSTANTS } from "constants/Constants";
import TableColAddress from "components/table/TableColAddress";
import { Helpers } from "helpers/Helpers";
import { TableAction } from "components/buttons/TableAction";
import { faUserTag } from "@fortawesome/free-solid-svg-icons";

class ReportBirthday extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      nClientsPerPage: 10,
      nMonth: parseInt(Helpers.ConvertTZ(new Date()).getMonth()) + 1
    };
  }
  componentDidMount = () => {
    const { nClientsPerPage, nMonth } = this.state;
    this.props.get(0, nClientsPerPage, nMonth);
  };
  componentWillUnmount = () => {
    this.props.cleanReports();
  };

  _handleSearchChange = (event) => {
    let { value } = event.target;
    this.setState({ search: value, currentPage: 1 });
  };

  _handleChangeMonth = async (event) => {
    const { nClientsPerPage } = this.state;
    let { value } = event.target;
    
    await this.setState({ nMonth: value, currentPage: 1 });
    
    // Usa el nuevo valor directamente en lugar del estado anterior
    this.props.get(0, nClientsPerPage, value);
};

 /*  _handleChangeMonth = async (event) => {
    const { nClientsPerPage, nMonth } = this.state;
    let { value } = event.target;
    await this.setState({ nMonth: value, currentPage: 1 });
    await this.props.get(0, nClientsPerPage, nMonth);
  }; */

  _handleexportExcel = () => {
    const { nMonth } = this.state;
    window.open(`${CONSTANTS.API_URL}reports/excel/birthdate?month=${nMonth}`, "_blank");
  };

  _handlechangeCount = async (event) => {
    const { nMonth } = this.state;
    let { value: nValue } = event.target;
    await this.setState({ nClientsPerPage: nValue, currentPage: 1 });
    this.props.get(0, nValue, nMonth);
  };

  _handleNextPage = async () => {
    let nClientsPerPage = this.state.nClientsPerPage;
    let nPage = this.state.currentPage * nClientsPerPage;
    await this.setState({ currentPage: this.state.currentPage + 1 });
    this.props.get(nPage, nClientsPerPage, this.state.nMonth);
  };

  _handlePreviousPage = async () => {
    await this.setState({ currentPage: this.state.currentPage - 2 });
    let nClientsPerPage = this.state.nClientsPerPage;
    let nPage;
    nPage = this.state.currentPage * nClientsPerPage;
    this.setState({ currentPage: this.state.currentPage + 1 });
    this.props.get(nPage, nClientsPerPage, this.state.nMonth);
  };

  _handleGoToPage = async (nPage) => {
    let nClientsPerPage = this.state.nClientsPerPage, nSkip = 0;
    nSkip = (nPage - 1) * nClientsPerPage;
    await this.setState({ currentPage: nPage });
    this.props.get(nSkip, nClientsPerPage, this.state.nMonth);
  };

  goToView = (event, nClientId, sRoute) => {
    event.stopPropagation();
    this.props.getClient(nClientId);
    this.props.history.push(sRoute);
  };

  downloadLabel = async (nClientId) => {
    window.open(`${CONSTANTS.API_URL}clients/downloadLabel/${nClientId}`, '_blank');
  }

  downloadAllLabels = async () => {
    const { nMonth } = this.state;
    window.open(`${CONSTANTS.API_URL}reports/allLabels?type=birthdate&month=${nMonth}`, '_blank');
  }

  renderTable = () => {
    return this.props.oBirthday.aBirthday.data.map((oClient, index) => {
      return (
        <Table.Row key={"client" + oClient.id}>
          <Table.Col className="cursor-pointer">
            <span
              onClick={(event) =>
                this.goToView(event, oClient.id, "/clients/edit/" + oClient.id)
              }
            >
              {oClient.fullname}
            </span>
          </Table.Col>
          <Table.Col>{oClient.code && oClient.code}</Table.Col>
          <Table.Col>
            {oClient.principal_phone && oClient.principal_phone}
          </Table.Col>
          <TableColAddress address={oClient.principal_addres} />
          <Table.Col className="text-center isActive-indicator">
            {oClient.birthdate &&
              oClient.birthdate.split("-").reverse().join("/")}
          </Table.Col>
          <Table.Col className="text-center isActive-indicator">
            {oClient.age && oClient.age}
          </Table.Col>
          <Table.Col className="text-center">
            <TableAction
              bFontAwesome={true}
              iconName={faUserTag}
              tooltip="Descargar etiqueta"
              onClick={() => this.downloadLabel(oClient.id)} />
          </Table.Col>
        </Table.Row>
      );
    });
  };

  render = () => {
    const { aBirthday } = this.props.oBirthday;
    const { nClientsPerPage } = this.state;
    return (
      <Card>
        <Card.Header>
          <Card.Title className="mr-auto">Listado de cumpleaños</Card.Title>
          <div className="card-filters mr-2">
            <Form.Select
              name="type"
              onChange={this._handleChangeMonth}
              value={this.state.nMonth}
            >
              {DATEPICKER.MONTH.map((oMonth, index) => {
                let nMonth = index + 1;
                return (
                  <option key={index} value={nMonth}>
                    {oMonth}
                  </option>
                );
              })}
            </Form.Select>
          </div>
          <Button
            color="primary"
            className="ml-2"
            onClick={this.downloadAllLabels}
          >
            <FontAwesomeIcon
              icon={faUserTag}
              className="mr-2"
            ></FontAwesomeIcon>
            Etiquetas
          </Button>
          <Button
            color="primary"
            className="ml-2"
            onClick={this._handleexportExcel}
          >
            <FontAwesomeIcon
              icon={faFileExcel}
              className="mr-2"
            ></FontAwesomeIcon>
            Exportar a excel
          </Button>
        </Card.Header>
        {aBirthday.data && aBirthday.data.length > 0 ? (
          <>
            <div className="table-responsive">
              <Table className="card-table table-vcenter table-sm table-hover text-nowrap">
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Apellido y Nombre</Table.ColHeader>
                    <Table.ColHeader>Número de socio</Table.ColHeader>
                    <Table.ColHeader>Teléfono</Table.ColHeader>
                    <Table.ColHeader>Domicilio</Table.ColHeader>
                    <Table.ColHeader alignContent="center">
                      Fecha de nacimiento
                    </Table.ColHeader>
                    <Table.ColHeader alignContent="center">
                      Edad
                    </Table.ColHeader>
                    <Table.ColHeader alignContent="center"></Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>{this.renderTable()}</Table.Body>
              </Table>
            </div>
            <Card.Footer>
              <Pagination
                nextPageAction={this._handleNextPage}
                previousPageAction={this._handlePreviousPage}
                goToPageAction={this._handleGoToPage}
                currentPage={this.state.currentPage}
                allRecords={aBirthday.allRecords}
                showing={Array.isArray(aBirthday.data) ? aBirthday.data.length : 0}
                nLastPage={Math.ceil(parseInt(aBirthday.allRecords) / parseInt(nClientsPerPage))}
                showAllOptions={true}
                _handlechangeCount={this._handlechangeCount}
              />
            </Card.Footer>
          </>
        ) : (
          <Card.Body>
            <Grid.Row>
              <Grid.Col>
                <Alert type="info" icon="info">
                  No hemos obtenido datos de clientes
                </Alert>
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
        )}
      </Card>
    );
  };
}

const mapStateToProps = (state) => ({
  oBirthday: state.reportsReducers,
});

const mapDispatchToProps = (dispatch) => ({
  getClient: (idClient) => {
    dispatch(actionGetClient(idClient));
  },
  get: (nPage, nClientsPerPage, nMonth) => {
    dispatch(actionGetBirthdateReport(nPage, nClientsPerPage, nMonth));
  },
  cleanReports: () => {
    dispatch(actionCleanReports());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportBirthday);
